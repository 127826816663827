import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import classNames from "classnames";

import {
  Layout,
  FeatureGridWithOffsetIcons,
  CTAAreYouReady,
  ContractingProcess,
  LinkButton,
  SEO,
} from "../../components";

import {
  ComputerDesktopIcon,
  CogIcon,
  ArrowPathIcon,
  BanknotesIcon,
  CheckIcon,
  XMarkIcon,
  ShieldCheckIcon,
  HeartIcon,
  CurrencyDollarIcon,
  PencilIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const benefits = [
  {
    name: "Cero Inversión Inicial",
    description:
      "Por tan sólo $0 mxn*, puedes empezar a disfrutar de energía solar bajo un esquema de renta mensual.",
    disclosure: "*Aplican restricciones. Consulta con tu Energy Expert.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Diseño Personalizado",
    description:
      "Elegimos el tamaño y la ubicación de tu sistema fotovoltaico de acuerdo a tus necesidades energéticas, la cantidad de sol y el tamaño de tu techo.",
    icon: PencilIcon,
  },
  {
    name: "Garantía",
    description:
      "Tenemos la garantía de producción más alta del mercado: Si tu sistema no produce lo acordado, te reembolsamos.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Monitoreo",
    description:
      "Supervisamos 24/7 que el sistema solar esté funcionando y dándote los resultados que mereces.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Trámites ante CFE",
    description:
      "Desde el papeleo hasta los permisos, nos encargaremos de todos los todos los aspectos para la instalación de tu sistema.",
    icon: LockClosedIcon,
  },
  {
    name: "Mantenimiento",
    description:
      "Nos aliamos con las mejores cuadrillas de instaladores en cada ciudad para asegurar que tu sistema esté en óptimas condiciones. Además, contamos con seguro contra daños de hasta $1,000,000 USD.",
    icon: CogIcon,
  },
];

const comparations = [
  {
    title: "CFE",
    featured: false,
  },
  {
    title: "Suscripción Bright",
    featured: true,
  },
  {
    title: "Prepago Bright",
    featured: false,
  },
];

const features = [
  {
    title: "Cero Inversión",
    tiers: [
      { title: "CFE", value: true },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: false },
    ],
  },
  {
    title: "Mantenimiento sin costo",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producto",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Garantía de producción con reembolso",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Seguro de instalación",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: true },
      { title: "Prepago", value: true },
    ],
  },
  {
    title: "Eres dueño del sistema",
    tiers: [
      { title: "CFE", value: false },
      { title: "Suscripción", featured: true, value: false },
      { title: "Prepago", value: false },
    ],
  },
];

const ResidentialPage = ({ data }) => {
  const brightSteps = getImage(data.brightSteps.childImageSharp);

  return (
    <Layout>
      <SEO
        title="Energía solar sin inversión para tu hogar"
        description="Bright Hogar es un servicio premium de energía solar por suscripción y prepago en México."
      />
      <div className="py-16 lg:py-24">
        <div className="mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="text-center">
            <h1 className="tracking-tight font-bold text-blue-500 text-4xl md:text-5xl">
              Energía solar para tu hogar
            </h1>

            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              ¡Nosotros invertimos en el sistema fotovoltaico por ti, y tú sólo
              gozas de los beneficios y ahorros!
            </p>
            <div className="mx-auto mt-6 max-w-xs w-56">
              <LinkButton arrow shadow size="large" to="/cotizar">
                Cotizar
              </LinkButton>
            </div>
          </div>
        </div>
      </div>

      {/* How it works Start */}
      <div className="bg-gray-50 py-16 lg:py-24">
        <div className="flex flex-col mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <h2 className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl text-center">
            Tú escoges cuánto quieres ahorrar
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Tenemos dos modalidades
          </p>
          <div className="mx-auto grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8 max-w-md lg:max-w-3xl xl:max-w-4xl">
            <div className="flex flex-col lg:flex-row mt-16 justify-center lg:justify-between ">
              <div className="flex flex-col bg-white rounded-lg border border-gray-100 shadow-md transform transition duration-200">
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-4 inline-block bg-yellow-500 rounded-lg transform -translate-y-1/2">
                    <ArrowPathIcon className="h-9 w-9 text-white" />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    Suscripción Bright
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    Mejora tu servicio de energía cambiándote a energía solar
                    sin invertir nada.
                  </p>
                  <p className="mt-4 text-base text-gray-500">
                    Empieza a ahorrar un 20% y fija tu tarifa para protegerte
                    contra la volatilidad del costo de la energía de CFE.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-16 justify-center lg:justify-between ">
              <div className="flex flex-col bg-white rounded-lg border border-gray-100 shadow-md transform transition duration-200">
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-4 inline-block bg-yellow-500 rounded-lg transform -translate-y-1/2">
                    <BanknotesIcon className="h-9 w-9 text-white" />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    Prepago Bright
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    Paga por adelantado todo tu servicio en un sólo pago y
                    olvídate de pagar una mensualidad por varios años.
                  </p>
                  <p className="mt-4 text-base text-gray-500">
                    El ahorro total del periodo es de aproximadamente 98%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Comparison Start */}
      {/* Feature comparison (up to lg) */}
      <div className="bg-gray-50">
        <div className="lg:hidden">
          <div className="max-w-2xl mx-auto py-16 px-6 space-y-16">
            {comparations.map((comparation, index) => (
              <div key={index} className="border-t border-gray-200">
                <div
                  className={classNames(
                    comparation.featured
                      ? "border-yellow-500"
                      : "border-transparent",
                    "-mt-px pt-6 border-t-2 sm:w-1/2",
                  )}
                >
                  <h3
                    className={classNames(
                      comparation.featured
                        ? "text-yellow-500"
                        : "text-gray-900",
                      "text-lg font-bold text-center",
                    )}
                  >
                    {comparation.title}
                  </h3>
                </div>
                <div className="mt-6 relative">
                  {/* Fake card background */}
                  <div className="hidden absolute inset-0 pointer-events-none sm:block">
                    <div
                      className={classNames(
                        comparation.featured ? "shadow-md" : "shadow",
                        "absolute right-0 w-1/2 h-full bg-white rounded-lg",
                      )}
                    />
                  </div>

                  <div
                    className={classNames(
                      comparation.featured
                        ? "ring-2 ring-yellow-500 shadow-md"
                        : "ring-1 ring-black ring-opacity-5 shadow",
                      "relative py-3 px-4 bg-white rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none",
                    )}
                  >
                    <dl className="divide-y divide-gray-200">
                      {features.map((feature) => (
                        <div
                          key={feature.title}
                          className="py-3 flex items-center justify-between sm:grid sm:grid-cols-2"
                        >
                          <dt className="pr-4 text-sm font-medium text-gray-600">
                            {feature.title}
                          </dt>
                          <dd className="flex items-center justify-end sm:px-4 sm:justify-center">
                            {typeof feature.tiers[index].value === "string" ? (
                              <span
                                className={classNames(
                                  feature.tiers[index].featured
                                    ? "text-yellow-500"
                                    : "text-gray-900",
                                  "text-sm font-medium",
                                )}
                              >
                                {feature.tiers[index].value}
                              </span>
                            ) : (
                              <>
                                {feature.tiers[index].value === true ? (
                                  <CheckIcon className="mx-auto h-7 w-7 text-yellow-500" />
                                ) : (
                                  <XMarkIcon className="mx-auto h-7 w-7 text-gray-400" />
                                )}
                              </>
                            )}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  {/* Fake card border */}
                  <div className="hidden absolute inset-0 pointer-events-none sm:block">
                    <div
                      className={classNames(
                        comparation.featured
                          ? "ring-2 ring-yellow-500"
                          : "ring-1 ring-black ring-opacity-5",
                        "absolute right-0 w-1/2 h-full rounded-lg",
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Feature comparison (lg+) */}
        <div className="hidden lg:block">
          <div className="max-w-7xl mx-auto pb-24 px-8 pt-8">
            <div className="w-full border-t border-gray-200 flex items-stretch">
              <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
                <h3 className="mt-auto text-lg font-bold text-gray-900">
                  Servicios
                </h3>
              </div>
              {comparations.map((comparation, comparationIdx) => (
                <div
                  key={comparation.title}
                  className={classNames(
                    comparationIdx === comparations.length - 1 ? "" : "pr-4",
                    "-mt-px pl-4 w-1/4",
                  )}
                >
                  <div
                    className={classNames(
                      comparation.featured
                        ? "border-yellow-500"
                        : "border-transparent",
                      "py-6 border-t-2",
                    )}
                  >
                    <p
                      className={classNames(
                        comparation.featured
                          ? "text-yellow-500"
                          : "text-gray-900",
                        "text-lg font-bold text-center",
                      )}
                    >
                      {comparation.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="relative">
              {/* Fake card backgrounds */}
              <div className="absolute inset-0 flex items-stretch pointer-events-none">
                <div className="w-1/4 pr-4" />
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
                <div className="w-1/4 px-4">
                  <div className="w-full h-full bg-white rounded-lg shadow-md" />
                </div>
                <div className="w-1/4 pl-4">
                  <div className="w-full h-full bg-white rounded-lg shadow" />
                </div>
              </div>

              <table className="relative w-full">
                <thead>
                  <tr className="text-left">
                    {comparations.map((comparation) => (
                      <th key={comparation.title} scope="col"></th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {features.map((feature) => (
                    <tr key={feature.title}>
                      <th
                        scope="row"
                        className="w-1/4 py-3 pr-4 text-left text-base font-medium text-gray-600"
                      >
                        {feature.title}
                      </th>
                      {feature.tiers.map((tier, tierIdx) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            tierIdx === feature.tiers.length - 1
                              ? "pl-4"
                              : "px-4",
                            "relative w-1/4 py-0 text-center",
                          )}
                        >
                          <span className="relative w-full h-full py-3">
                            <span>
                              {tier.value === true ? (
                                <CheckIcon className="mx-auto h-7 w-7 text-yellow-500" />
                              ) : (
                                <XMarkIcon className="mx-auto h-7 w-7 text-gray-400" />
                              )}
                            </span>
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Fake card borders */}
              <div className="absolute inset-0 flex items-stretch pointer-events-none">
                <div className="w-1/4 pr-4" />
                <div className="w-1/4 px-4">
                  <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                </div>
                <div className="w-1/4 px-4">
                  <div className="w-full h-full rounded-lg ring-2 ring-yellow-500 ring-opacity-100" />
                </div>
                <div className="w-1/4 pl-4">
                  <div className="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Comparison End */}
      <ContractingProcess image={brightSteps} />
      <FeatureGridWithOffsetIcons
        title="Beneficios de suscribirte con Bright"
        features={benefits}
      />
      <CTAAreYouReady />
    </Layout>
  );
};
export default ResidentialPage;

export const query = graphql`
  query {
    brightSteps: file(
      relativePath: { eq: "illustrations/4_steps_bright.png" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 700)
      }
    }
  }
`;
